.userProfile .list-group .list-group-item:hover {
  color: #00A6A7;
}

.buttonEdit {
	padding-top: 7px;
	padding-right: 7px;
	position: absolute;
	right: 0;
	top: 0;
}
