.user-info:hover {
  opacity: 1;
  color: lightgrey;
}

.user-info:hover .btn.delete-button{
  display: inline-block;
}

.btn.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
}

.user-info:hover .btn.profile-button{
  display: inline-block;
}

.btn.profile-button{
  position: absolute;
  top: 5px;
  left: 5px;
  display: none;
}

.card.user-info, .card.add-user {
  background-color: rgba(0, 166, 167, .25);
}

.card.team-info {
  background-color: rgba(171, 169, 171, .25);
  border-color: rgb(171, 169, 171) !important;
}

.card.team-info.active-team {
  background-color: rgba(0, 166, 167, .25);
  border-color: rgb(0, 166, 167) !important;
}

.card-subtitle {
  font-size: 70%;
}

.badge.card-subtitle{
  font-size: 50%;
  vertical-align: middle;
}
