.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.text-dark-blue {
  color: #1F2555;
}

.text-teal {
  color: #00A6A7;
}

.text-orange {
  color: #EC5A28;
}

.text-yellow {
  color: #F9CB55;
}

.text-white {
  color: #FFFFFF;
}

.text-light-grey {
  color: #D2D0CE;
}

.text-dark-grey {
  color: #ABA9AB;
}

.bg-dark-blue {
  background-color: #1F2555;
}

.bg-teal {
  background-color: #00A6A7;
}

.bg-orange {
  background-color: #EC5A28;
}

.bg-yellow {
  background-color: #F9CB55;
}

.bg-white {
  background-color: #FFFFFF;
}

.bg-light-grey {
  background-color: #D2D0CE;
}

.bg-dark-grey {
  background-color: #ABA9AB;
}

.border-dark-blue {
  border-color: #1F2555;
}

.border-teal {
  border-color: #00A6A7;
}

.border-orange {
  border-color: #EC5A28;
}

.border-yellow {
  border-color: #F9CB55;
}

.border-white {
  border-color: #FFFFFF;
}

.border-light-grey {
  border-color: #D2D0CE;
}

.border-dark-grey {
  border-color: #ABA9AB;
}

.font-condensed {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 300;
}

.font-light {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.font-bold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.font-extra-bold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
}

.top-10 {
  margin-top: 10px;
}

.top-20 {
  margin-top: 20px;
}

.top-30 {
  margin-top: 30px;
}

.top-40 {
  margin-top: 40px;
}

.top-50 {
  margin-top: 50px;
}

.bottom-10 {
  margin-bottom: 10px;
}

.bottom-20 {
  margin-bottom: 20px;
}

.bottom-30 {
  margin-bottom: 30px;
}

.bottom-40 {
  margin-bottom: 40px;
}

.bottom-50 {
  margin-bottom: 50px;
}

.left-10 {
  margin-left: 10px;
}

.left-20 {
  margin-left: 20px;
}

.left-30 {
  margin-left: 30px;
}

.left-40 {
  margin-left: 40px;
}

.left-50 {
  margin-left: 50px;
}

.right-10 {
  margin-right: 10px;
}

.right-20 {
  margin-right: 20px;
}

.right-30 {
  margin-right: 30px;
}

.right-40 {
  margin-right: 40px;
}

.right-50 {
  margin-right: 50px;
}

.card.border-teal {
  border-color: #00A6A7 !important;
}

.card.border-yellow {
  border-color: #F9CB55 !important;
}

.card.border-orange {
  border-color: #EC5A28 !important;
}

.btn-primary {
  background-color: #00A6A7 !important;
  border-color: #00A6A7 !important;
}

.btn-primary:hover {
  background-color: #008d8e !important;
  border-color: #008d8e !important;
}

.btn-success {
  background-color: #00A6A7 !important;
  border-color: #00A6A7 !important;
}

.btn-success:hover {
  background-color: #008d8e !important;
  border-color: #008d8e !important;
}

.btn-warning {
  background-color: #F9CB55 !important;
  border-color: #F9CB55 !important;
}

.btn-warning:hover {
  background-color: #f7bc24 !important;
  border-color: #f7bc24 !important;
}

.btn-danger {
  background-color: #EC5A28 !important;
  border-color: #EC5A28 !important;
}

.btn-danger:hover {
  background-color: #cf4212 !important;
  border-color: #cf4212 !important;
}

.disabled{
  pointer-events: none;
}

.horizontal-line {
  height: 2px;
  width: 100%;
  margin-bottom: 10px;
}

.hoverTeal:hover {
  color: white !important;
  background-color: #00A6A7 !important;
  cursor: pointer;
}


.btn:focus, .btn.focus {
  box-shadow: none !important;
}

.list-group-item-action:hover {
  color: white !important;
  background-color: #00A6A7 !important;
  cursor: pointer;
}

.Toastify__toast--error {
  background: #EC5A28 !important;
}

.Toastify__toast--success {
  background: #00A6A7 !important;
}

.Toastify__toast--warning {
  background: #F9CB55 !important;
}

.card {
  border-radius: 0 0.25em 0.25em 0.25em !important;
  border: 2px solid rgba(0, 0, 0, 0.125) !important;
}

.form-control:focus {
  border-color: #00A6A7 !important;
  box-shadow: 0 0 0 0 !important;
}

.badge.backlog {
  position: absolute;
  top: -25px;
  left: 5px;
  border-radius: 5px 5px 0 0;
  height: 25px;
}

.badge.calendar {
  margin-right: 3px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: small;
}

.col_mp:hover {
  background-color: #00A6A7 !important;
}

.section-header {
  border-left: 20px solid #00A6A7;
  width: fit-content;
  box-sizing: border-box;
  padding: 0 0 0 10px;
  margin: 0 0 30px 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  color: #1F2555;
}

.form-control.timezone-selector, .timezone-label {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  display: inline-block;
  width: fit-content;
}

.timezone-label {
  padding-right: 10px;
}

.details-box {
  border-left: 0px solid #00A6A7;
  padding: 5px;
  margin: 10px;
}

.details-box p {
  margin: 0 0 5px 0;
}

.details-box p:last-child {
  margin: 0;
}

.material-icons {
  vertical-align: middle;
  margin-right: 5px;
}

.rotation-item {
  border: 2px solid;
  background-color: rgba(171, 169, 171, .25);
  border-color: rgb(171, 169, 171);
  border-radius: 5px;
  padding: 5px;
}

.list-group-item.rotation-item {
  border: 2px solid;
  background-color: rgba(171, 169, 171, .25);
  border-color: rgb(171, 169, 171);
  border-radius: 5px;
  margin-bottom: 5px;
}

.list-group.backlog-card-item {
  margin-bottom: 10px;
}

.list-group.contact-button-item {
  margin-bottom: 10px;
}

.check {
  background: url("./assets/checkbox.svg") no-repeat;
  width: 30px;
  height: 30px;
  display: inline-block;
  padding-right: 3em;
  margin-left: 1em;
  cursor: pointer;
}

.cross {
  background: url("./assets/cross.svg") no-repeat;
  width: 30px;
  height: 30px;
  display: inline-block;
  padding-right: 3em;
  cursor: pointer;
}

.noteGCS {
  text-align: left;

}