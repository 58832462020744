.faq-section-header {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 1.5rem;
}

.faq-question-header {
  font-size: 1rem;
  margin-bottom: 0;
}

.faq-question-body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 1rem;
}

.faq-question-icon {
  font-size: 1rem;
  padding: 5px;
  vertical-align: middle;
}

.faq-note-icon {
  font-size: 2rem;
  padding: 5px;
  float: left;
}

.faq-note-text {
  margin-left: 50px;
}

.faq-question-updated {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 0.75rem;
  padding: 2px 5px 2px 5px;
  margin-left: 5px;
  border-radius: 10px;
}

.faq-image {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.faq-content-box {
  text-align: center;
  padding: 10px;
  border-radius: 25px;
  box-sizing: border-box;
  max-width: 80%;
  display: block;
  margin: 10px auto;
}

.faq-content-box p {
  margin-bottom: 0;
}

.faq-outline-icon {
  font-size: 5rem;
  line-height: 2rem;
  display: block;
  margin: 0 auto;
  margin-bottom: 25px;
  text-align: center;
  max-width: 80%;
}
