#coverage-table .coverage-table-header {
  vertical-align: middle;
  font-weight: bold;
  min-width: 250px;
  max-width: 250px;
}
#coverage-table .sticky-table-cell{
  border-right: 1px solid #D2D0CE;
  border-bottom: 1px solid #00A6A7 !important;
  min-width: 250px;
  max-width: 250px;
  overflow: hidden;
}
#coverage-table .sticky-table-cell * {
  border: none;
}
#coverage-table .sticky-table-row > :first-child{
  border-right: 1px solid #00A6A7 !important;
}
#coverage-table .coverage-table-cell-holiday{
  color: #EC5A28;
}
#coverage-table{
  max-height: 700px;
}

#coverage-table.large {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  max-height: 100%;
  top: 0px;
  z-index: 30644;
  border: 25px solid rgba(0,0,0,0.7);
  box-sizing: border-box;
  background-color: white;
}


.coverage-progress-container {
  width: 100%;
  margin-bottom: 20px;
}
.coverage-progress-label {
  width: 100%;
  display: block;
  font-size: 0.7rem;
  padding-top : 20px;
}
.coverage-progress-bar {
  width: 100%;
  display: block;
  height: 5px;
}

.coverage-progress-bar-openoffice, .coverage-progress-bar-oncall, .coverage-progress-bar-nocoverage,.coverage-progress-bar-hour{
  height: 15px;
  display: inline-block;
  width: 0%;
  overflow: hidden;
  
}
.legend {
  padding: 4px;
  display: block;
  float:right;
  color: white;
}


.coverage-progress-bar-hour div{
  line-height: 15px;
  color: black;
}

.coverage-progress-bar-hour div .blinkingRed{
  font-size: 20px;
  vertical-align: top;
  color: red;
  font-weight: bold;
  animation: blink 1s linear infinite;
}

@keyframes blink{
  0%{opacity: 0.5;}
  
  100%{opacity: 1;}
}