.settings {
  padding: 50px 10px;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.btn-group .btn.btn-primary.active {
  background: blue;
}

.btn-group .btn.btn-primary {
  background: grey;
}
