.selector-horizontal .selector-item {
  width: auto;
  padding: 5px;
  color: #000000;
  font-weight: 400;
  border-top: 1px solid #D2D0CE;
  border-bottom: 1px solid #D2D0CE;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
}

.selector-horizontal.count-1 .selector-item{
  width: 100%;
}

.selector-horizontal.count-2 .selector-item{
  width: 50%;
}

.selector-horizontal.count-3 .selector-item{
  width: 33.333333%;
}

.selector-horizontal.count-4 .selector-item{
  width: 25%;
}

.selector-horizontal .selector-item:first-child {
  border-left: 1px solid #D2D0CE;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.selector-horizontal .selector-item:last-child {
  border-right: 1px solid #D2D0CE;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.selector-horizontal .selector-item:hover {
  cursor: pointer;
  color: #00A6A7;
}

.selector-horizontal .selector-item.active {
  cursor: pointer;
  border: 3px solid #00A6A7;
  border-radius: 10px;
}

.selector-horizontal .selector-item.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  color: #000000;
}

.selector-horizontal .selector-item.disabled:hover {
  cursor: not-allowed;
  opacity: 0.5;
  color: #000000;
}

.selector-horizontal:not(:first-child), .selector-vertical{
  margin-top: 5px;
}

.selector-vertical .selector-item {
  padding: 5px;
  color: #000000;
  font-weight: 400;
  border-left: 1px solid #D2D0CE;
  border-right: 1px solid #D2D0CE;
  display: block;
  box-sizing: border-box;
}

.selector-vertical .selector-item:first-child {
  border-top: 1px solid #D2D0CE;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.selector-vertical .selector-item:last-child {
  border-bottom: 1px solid #D2D0CE;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.selector-vertical .selector-item:hover {
  cursor: pointer;
  color: #00A6A7;
}

.selector-vertical .selector-item.active {
  cursor: pointer;
  border: 3px solid #00A6A7;
  border-radius: 10px;
}

.selector-vertical .selector-item.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  color: #000000;
}

.selector-vertical .selector-item.disabled:hover {
  cursor: not-allowed;
  opacity: 0.5;
  color: #000000;
}

.rotation-numerator{
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  padding-right: 5px;
  margin-right: 5px;
  border-right: 2px solid;
}
