.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.text-dark-blue {
  color: #1F2555;
}

.text-teal {
  color: #00A6A7;
}

.text-orange {
  color: #EC5A28;
}

.text-yellow {
  color: #F9CB55;
}

.text-white {
  color: #FFFFFF;
}

.text-light-grey {
  color: #D2D0CE;
}

.text-dark-grey {
  color: #ABA9AB;
}

.bg-dark-blue {
  background-color: #1F2555;
}

.bg-teal {
  background-color: #00A6A7;
}

.bg-orange {
  background-color: #EC5A28;
}

.bg-yellow {
  background-color: #F9CB55;
}

.bg-white {
  background-color: #FFFFFF;
}

.bg-light-grey {
  background-color: #D2D0CE;
}

.bg-dark-grey {
  background-color: #ABA9AB;
}

.border-dark-blue {
  border-color: #1F2555;
}

.border-teal {
  border-color: #00A6A7;
}

.border-orange {
  border-color: #EC5A28;
}

.border-yellow {
  border-color: #F9CB55;
}

.border-white {
  border-color: #FFFFFF;
}

.border-light-grey {
  border-color: #D2D0CE;
}

.border-dark-grey {
  border-color: #ABA9AB;
}

.font-condensed {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 300;
}

.font-light {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.font-bold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.font-extra-bold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
}

.top-10 {
  margin-top: 10px;
}

.top-20 {
  margin-top: 20px;
}

.top-30 {
  margin-top: 30px;
}

.top-40 {
  margin-top: 40px;
}

.top-50 {
  margin-top: 50px;
}

.bottom-10 {
  margin-bottom: 10px;
}

.bottom-20 {
  margin-bottom: 20px;
}

.bottom-30 {
  margin-bottom: 30px;
}

.bottom-40 {
  margin-bottom: 40px;
}

.bottom-50 {
  margin-bottom: 50px;
}

.left-10 {
  margin-left: 10px;
}

.left-20 {
  margin-left: 20px;
}

.left-30 {
  margin-left: 30px;
}

.left-40 {
  margin-left: 40px;
}

.left-50 {
  margin-left: 50px;
}

.right-10 {
  margin-right: 10px;
}

.right-20 {
  margin-right: 20px;
}

.right-30 {
  margin-right: 30px;
}

.right-40 {
  margin-right: 40px;
}

.right-50 {
  margin-right: 50px;
}

.card.border-teal {
  border-color: #00A6A7 !important;
}

.card.border-yellow {
  border-color: #F9CB55 !important;
}

.card.border-orange {
  border-color: #EC5A28 !important;
}

.btn-primary {
  background-color: #00A6A7 !important;
  border-color: #00A6A7 !important;
}

.btn-primary:hover {
  background-color: #008d8e !important;
  border-color: #008d8e !important;
}

.btn-success {
  background-color: #00A6A7 !important;
  border-color: #00A6A7 !important;
}

.btn-success:hover {
  background-color: #008d8e !important;
  border-color: #008d8e !important;
}

.btn-warning {
  background-color: #F9CB55 !important;
  border-color: #F9CB55 !important;
}

.btn-warning:hover {
  background-color: #f7bc24 !important;
  border-color: #f7bc24 !important;
}

.btn-danger {
  background-color: #EC5A28 !important;
  border-color: #EC5A28 !important;
}

.btn-danger:hover {
  background-color: #cf4212 !important;
  border-color: #cf4212 !important;
}

.disabled{
  pointer-events: none;
}

.horizontal-line {
  height: 2px;
  width: 100%;
  margin-bottom: 10px;
}

.hoverTeal:hover {
  color: white !important;
  background-color: #00A6A7 !important;
  cursor: pointer;
}


.btn:focus, .btn.focus {
  box-shadow: none !important;
}

.list-group-item-action:hover {
  color: white !important;
  background-color: #00A6A7 !important;
  cursor: pointer;
}

.Toastify__toast--error {
  background: #EC5A28 !important;
}

.Toastify__toast--success {
  background: #00A6A7 !important;
}

.Toastify__toast--warning {
  background: #F9CB55 !important;
}

.card {
  border-radius: 0 0.25em 0.25em 0.25em !important;
  border: 2px solid rgba(0, 0, 0, 0.125) !important;
}

.form-control:focus {
  border-color: #00A6A7 !important;
  box-shadow: 0 0 0 0 !important;
}

.badge.backlog {
  position: absolute;
  top: -25px;
  left: 5px;
  border-radius: 5px 5px 0 0;
  height: 25px;
}

.badge.calendar {
  margin-right: 3px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: small;
}

.col_mp:hover {
  background-color: #00A6A7 !important;
}

.section-header {
  border-left: 20px solid #00A6A7;
  width: fit-content;
  box-sizing: border-box;
  padding: 0 0 0 10px;
  margin: 0 0 30px 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  color: #1F2555;
}

.form-control.timezone-selector, .timezone-label {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  display: inline-block;
  width: fit-content;
}

.timezone-label {
  padding-right: 10px;
}

.details-box {
  border-left: 0px solid #00A6A7;
  padding: 5px;
  margin: 10px;
}

.details-box p {
  margin: 0 0 5px 0;
}

.details-box p:last-child {
  margin: 0;
}

.material-icons {
  vertical-align: middle;
  margin-right: 5px;
}

.rotation-item {
  border: 2px solid;
  background-color: rgba(171, 169, 171, .25);
  border-color: rgb(171, 169, 171);
  border-radius: 5px;
  padding: 5px;
}

.list-group-item.rotation-item {
  border: 2px solid;
  background-color: rgba(171, 169, 171, .25);
  border-color: rgb(171, 169, 171);
  border-radius: 5px;
  margin-bottom: 5px;
}

.list-group.backlog-card-item {
  margin-bottom: 10px;
}

.list-group.contact-button-item {
  margin-bottom: 10px;
}

.check {
  background: url(/static/media/checkbox.f30654a3.svg) no-repeat;
  width: 30px;
  height: 30px;
  display: inline-block;
  padding-right: 3em;
  margin-left: 1em;
  cursor: pointer;
}

.cross {
  background: url(/static/media/cross.9e04cba1.svg) no-repeat;
  width: 30px;
  height: 30px;
  display: inline-block;
  padding-right: 3em;
  cursor: pointer;
}

.noteGCS {
  text-align: left;

}
#coverage-table .coverage-table-header {
  vertical-align: middle;
  font-weight: bold;
  min-width: 250px;
  max-width: 250px;
}
#coverage-table .sticky-table-cell{
  border-right: 1px solid #D2D0CE;
  border-bottom: 1px solid #00A6A7 !important;
  min-width: 250px;
  max-width: 250px;
  overflow: hidden;
}
#coverage-table .sticky-table-cell * {
  border: none;
}
#coverage-table .sticky-table-row > :first-child{
  border-right: 1px solid #00A6A7 !important;
}
#coverage-table .coverage-table-cell-holiday{
  color: #EC5A28;
}
#coverage-table{
  max-height: 700px;
}

#coverage-table.large {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  max-height: 100%;
  top: 0px;
  z-index: 30644;
  border: 25px solid rgba(0,0,0,0.7);
  box-sizing: border-box;
  background-color: white;
}


.coverage-progress-container {
  width: 100%;
  margin-bottom: 20px;
}
.coverage-progress-label {
  width: 100%;
  display: block;
  font-size: 0.7rem;
  padding-top : 20px;
}
.coverage-progress-bar {
  width: 100%;
  display: block;
  height: 5px;
}

.coverage-progress-bar-openoffice, .coverage-progress-bar-oncall, .coverage-progress-bar-nocoverage,.coverage-progress-bar-hour{
  height: 15px;
  display: inline-block;
  width: 0%;
  overflow: hidden;
  
}
.legend {
  padding: 4px;
  display: block;
  float:right;
  color: white;
}


.coverage-progress-bar-hour div{
  line-height: 15px;
  color: black;
}

.coverage-progress-bar-hour div .blinkingRed{
  font-size: 20px;
  vertical-align: top;
  color: red;
  font-weight: bold;
  animation: blink 1s linear infinite;
}

@keyframes blink{
  0%{opacity: 0.5;}
  
  100%{opacity: 1;}
}
.userProfile .list-group .list-group-item:hover {
  color: #00A6A7;
}

.buttonEdit {
	padding-top: 7px;
	padding-right: 7px;
	position: absolute;
	right: 0;
	top: 0;
}

.faq-section-header {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 1.5rem;
}

.faq-question-header {
  font-size: 1rem;
  margin-bottom: 0;
}

.faq-question-body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 1rem;
}

.faq-question-icon {
  font-size: 1rem;
  padding: 5px;
  vertical-align: middle;
}

.faq-note-icon {
  font-size: 2rem;
  padding: 5px;
  float: left;
}

.faq-note-text {
  margin-left: 50px;
}

.faq-question-updated {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 0.75rem;
  padding: 2px 5px 2px 5px;
  margin-left: 5px;
  border-radius: 10px;
}

.faq-image {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.faq-content-box {
  text-align: center;
  padding: 10px;
  border-radius: 25px;
  box-sizing: border-box;
  max-width: 80%;
  display: block;
  margin: 10px auto;
}

.faq-content-box p {
  margin-bottom: 0;
}

.faq-outline-icon {
  font-size: 5rem;
  line-height: 2rem;
  display: block;
  margin: 0 auto;
  margin-bottom: 25px;
  text-align: center;
  max-width: 80%;
}

#comp-table .comp-table-header {
  vertical-align: middle;
  font-weight: bold;
}
#comp-table .sticky-table-cell{
  border-right: 1px solid #D2D0CE;
  border-bottom: 1px solid #00A6A7 !important;
}
#comp-table .sticky-table-row :first-child{
  border-right: 1px solid #00A6A7 !important;
}
#comp-table .comp-table-cell-holiday{
  color: #EC5A28;
}
#comp-table .sticky-table-row:hover .sticky-table-cell.comp-table-body{
  background-color: #00A6A7 !important;
  color: #FFFFFF;
}
#comp-table .sticky-table-row:hover :first-child{
  border-right: 1px solid #D2D0CE !important;
}

#comp-table{
  max-height: 700px;
}

#comp-table.large {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  top: 0px;
  z-index: 30644;
  border: 25px solid rgba(0,0,0,0.7);
  box-sizing: border-box;
  background-color: white;
}

.selector-horizontal .selector-item {
  width: auto;
  padding: 5px;
  color: #000000;
  font-weight: 400;
  border-top: 1px solid #D2D0CE;
  border-bottom: 1px solid #D2D0CE;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
}

.selector-horizontal.count-1 .selector-item{
  width: 100%;
}

.selector-horizontal.count-2 .selector-item{
  width: 50%;
}

.selector-horizontal.count-3 .selector-item{
  width: 33.333333%;
}

.selector-horizontal.count-4 .selector-item{
  width: 25%;
}

.selector-horizontal .selector-item:first-child {
  border-left: 1px solid #D2D0CE;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.selector-horizontal .selector-item:last-child {
  border-right: 1px solid #D2D0CE;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.selector-horizontal .selector-item:hover {
  cursor: pointer;
  color: #00A6A7;
}

.selector-horizontal .selector-item.active {
  cursor: pointer;
  border: 3px solid #00A6A7;
  border-radius: 10px;
}

.selector-horizontal .selector-item.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  color: #000000;
}

.selector-horizontal .selector-item.disabled:hover {
  cursor: not-allowed;
  opacity: 0.5;
  color: #000000;
}

.selector-horizontal:not(:first-child), .selector-vertical{
  margin-top: 5px;
}

.selector-vertical .selector-item {
  padding: 5px;
  color: #000000;
  font-weight: 400;
  border-left: 1px solid #D2D0CE;
  border-right: 1px solid #D2D0CE;
  display: block;
  box-sizing: border-box;
}

.selector-vertical .selector-item:first-child {
  border-top: 1px solid #D2D0CE;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.selector-vertical .selector-item:last-child {
  border-bottom: 1px solid #D2D0CE;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.selector-vertical .selector-item:hover {
  cursor: pointer;
  color: #00A6A7;
}

.selector-vertical .selector-item.active {
  cursor: pointer;
  border: 3px solid #00A6A7;
  border-radius: 10px;
}

.selector-vertical .selector-item.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  color: #000000;
}

.selector-vertical .selector-item.disabled:hover {
  cursor: not-allowed;
  opacity: 0.5;
  color: #000000;
}

.rotation-numerator{
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  padding-right: 5px;
  margin-right: 5px;
  border-right: 2px solid;
}

.DayPicker-Day {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
}

.DayPicker-Day > .cellStyle {
  height: 100px;
  width: 100px;
  position: relative;
  border: 1px solid lightgrey;
}

 .DayPicker-Day > .cellStyle > .dateStyle {
  position: absolute;
  color: black;
  top: 0;
  left: 0;
  font-size: 20px;
}

.DayPicker-Day--disabled > .cellStyle > .dateStyle {
 color: darkgrey;
}

.DayPicker-Day > .cellStyle > .dateStyle > .holidayStyle {
  font-size: 12px;
  font-weight: 800;
  color: #EC5A28;
  padding: 2px;
  vertical-align: middle;
  height: 26px;
  display: inline-block;
}

.DayPicker-Day > .cellStyle > .rotationStyle {
  font-size: 14px;
  font-weight: 600;
  color:#5f5f5eef;
  padding: 25px;
  text-align: center;
  bottom: 10px;
  right: 10px;
  
}

.DayPicker-Day > .readOnly {
  cursor: pointer;
}

.DayPicker-Day .currentRotationStyle {
  color: #00A6A7;
  padding: 2px;
}

.DayPicker-Day .DayPicker-Day--selected .holidayStyle {
  font-size: 7px;
  font-weight: 800;
  color: white;
  padding: 2px;
}

.DayPicker-Day--selected {
  border-radius: 10px;
}

.DayPicker-Day .engineer {
  font-size: 75%;
  font-weight: 400;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #00A6A7;
  color: white;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #008B8D;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: transparent;
}

.Toastify__toast {
border-radius: 20px;
}

@media (max-width: 576px) {
  .DayPicker-Day {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
  }

  .DayPicker-Day > .cellStyle {
    height: 80px;
    width: 40px;
    position: relative;
    border: 1px solid lightgrey;
  }

   .DayPicker-Day > .cellStyle > .dateStyle {
    position: absolute;
    color: black;
    bottom: 0;
    right: 0;
    font-size: 8px;
  }

  .DayPicker-Day--disabled > .cellStyle > .dateStyle {
   color: darkgrey;
  }

  .DayPicker-Day > .cellStyle > .holidayStyle {
    font-size: 5px;
    font-weight: 800;
    color: #EC5A28;
    padding: 2px;
  }

  .DayPicker-Day > .cellStyle > .rotationStyle {
    font-size: 8px;
    font-weight: 800;
    color: #000000;
    padding: 3px;
    text-align: center;
    bottom: 2px;
    position: absolute;
  }
}

.DayPicker-Day--weekends, .DayPicker-Day--weekends:hover {
  background-color: rgba(249,203,85,0.5) !important;
  border-radius: 0;
}

.DayPicker-Day--outside, .DayPicker-Day--outside:hover {
  background-color: rgba(200, 179, 127, 0) !important;
  border-radius: 0;
}

.rotation-table tr td {
  padding: 0.8rem 0.3rem;
  vertical-align: middle;
}

.rotation-table th {
  font-size: 13px;
}

.modal-header {
  position: relative;
}

.rt-modal-close {
  position: absolute;
  top: 11px;
  right: 5px;
}

/* .rotationCalendarSec .modal-header{
  display: block;
  position: relative;
}

.rotationCalendarSec .modal-header .closeIcon{ 
  cursor: pointer;
} */
.settings {
  padding: 50px 10px;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.btn-group .btn.btn-primary.active {
  background: blue;
}

.btn-group .btn.btn-primary {
  background: grey;
}

.user-info:hover {
  opacity: 1;
  color: lightgrey;
}

.user-info:hover .btn.delete-button{
  display: inline-block;
}

.btn.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
}

.user-info:hover .btn.profile-button{
  display: inline-block;
}

.btn.profile-button{
  position: absolute;
  top: 5px;
  left: 5px;
  display: none;
}

.card.user-info, .card.add-user {
  background-color: rgba(0, 166, 167, .25);
}

.card.team-info {
  background-color: rgba(171, 169, 171, .25);
  border-color: rgb(171, 169, 171) !important;
}

.card.team-info.active-team {
  background-color: rgba(0, 166, 167, .25);
  border-color: rgb(0, 166, 167) !important;
}

.card-subtitle {
  font-size: 70%;
}

.badge.card-subtitle{
  font-size: 50%;
  vertical-align: middle;
}

.SMEProfile .list-group-item {
    padding: auto;
}
