.DayPicker-Day {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
}

.DayPicker-Day > .cellStyle {
  height: 100px;
  width: 100px;
  position: relative;
  border: 1px solid lightgrey;
}

 .DayPicker-Day > .cellStyle > .dateStyle {
  position: absolute;
  color: black;
  top: 0;
  left: 0;
  font-size: 20px;
}

.DayPicker-Day--disabled > .cellStyle > .dateStyle {
 color: darkgrey;
}

.DayPicker-Day > .cellStyle > .dateStyle > .holidayStyle {
  font-size: 12px;
  font-weight: 800;
  color: #EC5A28;
  padding: 2px;
  vertical-align: middle;
  height: 26px;
  display: inline-block;
}

.DayPicker-Day > .cellStyle > .rotationStyle {
  font-size: 14px;
  font-weight: 600;
  color:#5f5f5eef;
  padding: 25px;
  text-align: center;
  bottom: 10px;
  right: 10px;
  
}

.DayPicker-Day > .readOnly {
  cursor: pointer;
}

.DayPicker-Day .currentRotationStyle {
  color: #00A6A7;
  padding: 2px;
}

.DayPicker-Day .DayPicker-Day--selected .holidayStyle {
  font-size: 7px;
  font-weight: 800;
  color: white;
  padding: 2px;
}

.DayPicker-Day--selected {
  border-radius: 10px;
}

.DayPicker-Day .engineer {
  font-size: 75%;
  font-weight: 400;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #00A6A7;
  color: white;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #008B8D;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: transparent;
}

.Toastify__toast {
border-radius: 20px;
}

@media (max-width: 576px) {
  .DayPicker-Day {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
  }

  .DayPicker-Day > .cellStyle {
    height: 80px;
    width: 40px;
    position: relative;
    border: 1px solid lightgrey;
  }

   .DayPicker-Day > .cellStyle > .dateStyle {
    position: absolute;
    color: black;
    bottom: 0;
    right: 0;
    font-size: 8px;
  }

  .DayPicker-Day--disabled > .cellStyle > .dateStyle {
   color: darkgrey;
  }

  .DayPicker-Day > .cellStyle > .holidayStyle {
    font-size: 5px;
    font-weight: 800;
    color: #EC5A28;
    padding: 2px;
  }

  .DayPicker-Day > .cellStyle > .rotationStyle {
    font-size: 8px;
    font-weight: 800;
    color: #000000;
    padding: 3px;
    text-align: center;
    bottom: 2px;
    position: absolute;
  }
}

.DayPicker-Day--weekends, .DayPicker-Day--weekends:hover {
  background-color: rgba(249,203,85,0.5) !important;
  border-radius: 0;
}

.DayPicker-Day--outside, .DayPicker-Day--outside:hover {
  background-color: rgba(200, 179, 127, 0) !important;
  border-radius: 0;
}

.rotation-table tr td {
  padding: 0.8rem 0.3rem;
  vertical-align: middle;
}

.rotation-table th {
  font-size: 13px;
}

.modal-header {
  position: relative;
}

.rt-modal-close {
  position: absolute;
  top: 11px;
  right: 5px;
}

/* .rotationCalendarSec .modal-header{
  display: block;
  position: relative;
}

.rotationCalendarSec .modal-header .closeIcon{ 
  cursor: pointer;
} */