#comp-table .comp-table-header {
  vertical-align: middle;
  font-weight: bold;
}
#comp-table .sticky-table-cell{
  border-right: 1px solid #D2D0CE;
  border-bottom: 1px solid #00A6A7 !important;
}
#comp-table .sticky-table-row :first-child{
  border-right: 1px solid #00A6A7 !important;
}
#comp-table .comp-table-cell-holiday{
  color: #EC5A28;
}
#comp-table .sticky-table-row:hover .sticky-table-cell.comp-table-body{
  background-color: #00A6A7 !important;
  color: #FFFFFF;
}
#comp-table .sticky-table-row:hover :first-child{
  border-right: 1px solid #D2D0CE !important;
}

#comp-table{
  max-height: 700px;
}

#comp-table.large {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  top: 0px;
  z-index: 30644;
  border: 25px solid rgba(0,0,0,0.7);
  box-sizing: border-box;
  background-color: white;
}
